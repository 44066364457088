import React from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { FrontasticProvider, URLHistoryProvider } from '@frontastic-engbers/lib';
import Toaster from '@engbers/components/online-shops/commercetools-ui/toaster';
import 'tailwindcss/tailwind.css';
import '../styles/app.scss';
import '../styles/themes/default.scss';
import '../styles/themes/theme1.scss';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
import { AnimatePresence } from 'framer-motion';

function FrontasticStarter({ Component, pageProps, router }: AppProps) {
  return (
    <FrontasticProvider context="pwa">
      <URLHistoryProvider>
        <AnimatePresence exitBeforeEnter>
          <Component key={router.asPath} {...pageProps} />
          <Toaster />
        </AnimatePresence>
      </URLHistoryProvider>
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
